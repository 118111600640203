<template>
  <div class="d-flex box">
    <v-card
      height="900px"
      flat
      color="#fff"
      class="mr-7 text-body-2 card-warp"
      width="340px"
    >
      <v-treeview
        activatable
        :items="roleItems"
        color="primary"
        open-on-click
        dense
        class="pa-4 rounded-lg"
        @update:active="updateActive"
      ></v-treeview>
    </v-card>
    <v-card flat color="#fff" class="right text-body-2 rounded-lg">
      <v-tabs v-model="tab" align-with-title class="px-5">
        <div
          style="
            width: 100%;
            position: absolute;
            bottom: 0;
            background-color: #c2c2c2;
            height: 2px;
          "
        ></div>
        <v-tab v-for="(item, index) in tabItems" :key="item">
          <div
            :style="{
              color: tab === index ? '#282D30' : '',
              fontWeight: tab === index ? 900 : '',
            }"
            class="tab-title"
          >
            {{ item }}
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-show="tab === 0" class="pa-5">
          <template v-if="selectedMenu.length > 0">
            <v-row
              style="font-size: 16px"
              class="primary white--text font-weight-medium mx-auto mt-8 mb-5"
            >
              <v-col sm="3" lg="2">一级菜单</v-col>
              <v-col>二级菜单</v-col>
            </v-row>
            <v-row
              v-for="menuTree in computedMenutreeItems"
              :key="menuTree.id"
              class="row-check-box-warp"
            >
              <v-col class="row-check-box" sm="3" lg="2">{{
                menuTree.menuName
              }}</v-col>
              <v-col class="d-flex flex-wrap row-check-box">
                <div v-for="menu in menuTree.children" :key="menu.id">
                  <k-checkbox
                    v-model="menu.modelValue"
                    :label="menu.menuName"
                    class="mr-8"
                    @click="onChangeMenuAuthority(menu, '0')"
                  />
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <span>请选择角色组</span>
          </template>
        </v-tab-item>
        <v-tab-item v-show="tab === 1" class="pa-5">
          <template v-if="selectedMenu.length > 0">
            <v-row
              style="font-size: 16px"
              class="primary white--text font-weight-medium mx-auto mt-8 mb-5"
            >
              <v-col sm="3" lg="2">操作项目</v-col>
              <v-col>权限</v-col>
            </v-row>
            <v-row
              v-for="authorityTree in computedAuthorityTreeItems"
              :key="authorityTree.id"
              class="row-check-box-warp"
            >
              <v-col sm="3" lg="2">{{ authorityTree.menuName }}</v-col>
              <v-col class="d-flex flex-wrap">
                <div v-for="menu in authorityTree.children" :key="menu.id">
                  <k-checkbox
                    v-model="menu.modelValue"
                    :label="menu.menuName"
                    class="mr-8"
                    @click="onChangeMenuAuthority(menu, '1')"
                  />
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <span>请选择角色组</span>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
  data() {
    return {
      tab: '',
      roleItems: [],
      menutreeItems: [],
      authorityTreeItems: [],
      selectedMenuAuthorities: [],
      tabItems: ['菜单配置', '操作权限'],
      selectedMenu: [],
    };
  },
  computed: {
    computedMenutreeItems() {
      this.menutreeItems.forEach((menu) => {
        if (menu.children && menu.children.length > 0) {
          menu.children.forEach((item) => {
            Vue.set(
              item,
              'modelValue',
              this.selectedMenuAuthorities.includes(item.id)
            );
          });
        }
      });
      return this.menutreeItems;
    },

    computedAuthorityTreeItems() {
      this.authorityTreeItems.forEach((menu) => {
        if (menu.children && menu.children.length > 0) {
          menu.children.forEach((item) => {
            Vue.set(
              item,
              'modelValue',
              this.selectedMenuAuthorities.includes(item.id)
            );
          });
        }
      });
      return this.authorityTreeItems;
    },
  },
  created() {},
  mounted() {
    this.$api.system.role
      .getRoleGroup({
        timestamp: new Date().getTime(),
      })
      .then((res) => {
        if (res.data) {
          this.roleItems = res.data;
        }
      });

    this.$api.dev.menu.getMenu().then((res) => {
      if (res.data) {
        this.menutreeItems = res.data;
      }
    });

    this.$api.dev.authority.getAuthority().then((res) => {
      if (res.data) {
        this.authorityTreeItems = res.data;
      }
    });
  },
  methods: {
    updateActive(selectedMenu) {
      this.selectedMenuAuthorities = [];
      this.selectedMenu = selectedMenu;
      if (this.selectedMenu.length > 0) {
        this.$api.dev.authority
          .getPermissionById({
            roleId: this.selectedMenu[0],
            type: '0', // 0: 菜单配置； 1: 权限配置 此TYPE没用。
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.selectedMenuAuthorities = res.data;
            }
            // this.$nextTick(() => {
            //   this.$forceUpdate();
            // });
          });
      }
    },
    onChangeMenuAuthority(menu, type) {
      var toUpdateIds = [];
      var toUpdateIds0 = [];
      var toUpdateIds1 = [];
      // if (type == '0') {
      toUpdateIds0 = this.menutreeItems
        .map((item) => item.children)
        .flat()
        .filter((item) => item.modelValue)
        .map((item) => item.id);
      // } else {
      toUpdateIds1 = this.authorityTreeItems
        .map((item) => item.children)
        .flat()
        .filter((item) => item.modelValue)
        .map((item) => item.id);
      // }

      toUpdateIds = toUpdateIds0.concat(toUpdateIds1);

      console.log('toUpdateIds:', toUpdateIds);

      // check
      if (!menu.modelValue) {
        toUpdateIds.push(menu.id);
        // uncheck
      } else {
        toUpdateIds = toUpdateIds.filter((item) => {
          return item != menu.id;
        });
      }

      this.$api.dev.authority
        .updateRoleAuthority({
          roleId: this.selectedMenu[0],
          menuIds: toUpdateIds,
          type: type, // 0: 菜单配置； 1: 权限配置
        })
        .then(() => {
          this.$alert.success('更新权限成功');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-treeview-node--active {
  color: white !important;
  background: #2e71fe !important;
  border-radius: 5px !important;
  &::before {
    opacity: 0 !important;
  }
}
.row-check-box-warp {
  margin: 0 auto;
  &:nth-child(odd) {
    background: #f8faff;
  }
}
.tab-title {
  font-size: 18px;
  color: #606266;
}
.right {
  width: 100%;
}
</style>
